import { BrowserRouter as Router } from 'react-router-dom';
import { useWebAnalytics } from './components';
import Routes from './routes/Routes';

function App() {
  useWebAnalytics();
  
	return (
		<Router>
      <Routes />
    </Router>
  );
}

export default App;