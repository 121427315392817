import React from 'react';
import { MenuItems } from './Menus';
import { DesktopNavRoutes } from '../routes/DesktopNavRoutes';
import '../styles/Navbar.css';

export default function Navbar() {
  return (
    <nav className="navbar">
      <ul className='nav-menu'>
        {DesktopNavRoutes.map((menu) => {
          return <MenuItems 
            key={menu.id} 
            id={menu.id} 
            items={menu} 
          />;
        })}
      </ul>
    </nav>
  );
}