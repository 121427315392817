import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DesktopFooterRoutes from '../routes/DesktopFooterRoutes';
import MobileFooterRoutes from '../routes/MobileFooterRoutes';
import '../styles/Footer.css';

export default function Footer({desktopBg, mobileBg}) {
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  const footerSwitchRender = (route, device) => {
    switch(route.type) {
      case 'image':
        return (
          <a className="access-link" href={route.path} target={route.target} rel={route.rel}>
            <img className={device === "PC" ? "socials-img" : ""} src={route.icon} alt={route.alt} />
          </a>
        )
      case 'external':
        return (
          <a className={device === "PC" ? "access-link" : "mobile-access-link"} href={route.path} target={route.target} rel={route.rel}>{route.label}</a>
        )
      default:
        return (
          <Link className={device === "PC" ? "access-link" : "mobile-access-link"} to={route.path}>{route.label}</Link>
        )
    }
  }

  useEffect(() => {
    window.addEventListener("resize", detectSize)

    return () => {
      window.removeEventListener("resize", detectSize)
    }
  }, [windowDimension.winWidth])
  return (
  <>
    {windowDimension.winWidth > 651 ?
      <div className={`footer ${desktopBg}`}>
        <div className="footer-wrapper">
          <div className="footer-content">
            {DesktopFooterRoutes.map((segment, index) => (
              <div key={index.id} id={index.id} className="footer-segment">
                {Object.keys(segment).map((partName, m) => (
                partName === "SOCIAL LINKS" ?
                <div key={m.id} id={m.id} className="charity-socials">
                  {segment[partName].map((route, n) => (
                    <div key={n.id} id={n.id}>
                      { footerSwitchRender(route, 'PC') }
                    </div>
                  ))}
                </div>
                :
                <div>
                  <h2 className="link-heading">{partName}</h2>
                  <ul>
                    {segment[partName].map((route, n) => (
                      <li key={n.id} id={n.id} className={route.class}>
                        { footerSwitchRender(route, 'PC') }
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            ))}
          </div> 
          <div className="charity-status">
            Humanitarian Operations is a Registered Charity in England &amp; Wales
            (No. 1183873)
          </div>
        </div>
      </div> :
      <div className={`${mobileBg}`}>
        <div className="mobile-footer-content">
          {MobileFooterRoutes.map((segment, index) => (
            <div key={index.id} id={index.id} className="mobile-footer-segment">
              {Object.keys(segment).map((partName, m) => (
                partName === "SOCIAL LINKS" ?
                <div key={m.id} id={m.id} className="charity-socials">
                  {segment[partName].map((route, n) => (
                    <div key={n.id} id={n.id}>
                      { footerSwitchRender(route, 'Mobile') }
                    </div>
                  ))}
                </div>
                :
                <div>
                  <h2 className="mobile-link-heading">{partName}</h2>
                  <ul className="mobile-footer-list">
                    {segment[partName].map((route, n) => (
                      <li key={n.id} id={n.id} className={route.class}>
                        { footerSwitchRender(route, 'Mobile') }
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className="charity-details">
        <div className="charity-status">
          Humanitarian Operations is a Registered Charity in England &amp; Wales (No. 1183873)
        </div>
      </div>
    </div>}
  </>
  );
};