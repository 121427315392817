import { useEffect } from 'react';

const useWebAnalytics = () => {
  //The tracking code
  const websiteId = "971f6af4-d028-408d-8b0a-63388d72eb47"
  const analyticsUrl = "https://analytics.hope5d.com/umami.js"

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.setAttribute("src", analyticsUrl)
    script.setAttribute("data-website-id", websiteId)

    document.head.appendChild(script)

    return () => {
        document.head.removeChild(script)
    }

  })
}

export default useWebAnalytics
