import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CgMenu, CgClose } from 'react-icons/cg';
import MobileNavRoutes from '../../routes/MobileNavRoutes';

function MobileNavbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className='mobile-nav-items'>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CgClose className="menu-icon" />
        ) : (
          <CgMenu className="menu-icon" />
        )}
      </div>
      <ul className={click ? "mobile-items active" : "mobile-items"}>
        {MobileNavRoutes.map((mobileMenu) => (
          <li key={mobileMenu.id} id={mobileMenu.id}>
            {mobileMenu.item === "Title" ? (
              <span className="mobile-title">
                {mobileMenu.label}
              </span>
            ) : mobileMenu.item === "TitleLink" ? (
              <a 
                href={mobileMenu.path} 
                target={mobileMenu.target} 
                rel={mobileMenu.rel}
                className="mobile-title-link"
              >
                <span className="mobile-shop-link">
                  {mobileMenu.label}
                </span>
              </a>
            ) : (
              (mobileMenu.path.includes("http") ? (
                <a 
                  href={mobileMenu.path} 
                  target={mobileMenu.target} 
                  rel={mobileMenu.rel}
                  className="mobile-link"
                >
                  {mobileMenu.label}
                </a>
                ) : (
                <Link to={mobileMenu.path} className="mobile-link">
                  {mobileMenu.label}
                </Link>
                )
              )
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MobileNavbar