const MobileNavRoutes = [
  {
    id: "mobMenuTitle01",
    item: "Title",
    label: "About Us"
  },
  {
    id: "mobMenuLink01-1",
    item: "Link",
    label: "About HOPE",
    path:"/about-us",
  },
  {
    id: "mobMenuLink01-2",
    item: "Link",
    path:"/ziggy's-story",
    label: "Ziggy's Story"
  },
  {
    id: "mobMenuTitle02",
    item: "Title",
    label: "What We Do"
  },
  {
    id: "mobMenuLink02-1",
    item: "Link",
    label: "Charitable Operations",
    path:"/charitable-operations"
  },
  {
    id: "mobMenuLink02-2",
    item: "Link",
    label: "Disaster Relief",
    path:"/disaster-relief"
  },
  {
    id: "mobMenuLink02-3",
    item: "Link",
    label: "Endangered Animals",
    path:"/endangered-animals"
  },
  {
    id: "mobMenuLink02-4",
    item: "Link",
    label: "Free Education for Kids",
    path:"/page-under-development"
  },
  {
    id: "mobMenuTitle03",
    item: "Title",
    label: "How You Can Help"
  },
  {
    id: "mobMenuLink03-1",
    item: "Link",
    label: "Donate",
    path:"https://www.paypal.com/donate/?hosted_button_id=J7NBS6WZ67U52",
    target: "_blank",
    rel: "noreferrer noopener"
  },
  {
    id: "mobMenuLink03-2",
    item: "Link",
    label: "Shop to Help",
    path:"https://www.hope5d.com",
    target: "_blank",
    rel: "noreferrer noopener"
  },
  {
    id: "mobMenuTitle04",
    item: "Title",
    label: "You Can Be Part of It"
  },
  {
    id: "mobMenuLink04-1",
    item: "Link",
    label: "Little Heroes Big Mission",
    path:"/little-heroes-big-missions"
  },
  {
    id: "mobMenuLink04-2",
    item: "Link",
    label: "Become a Little Hero",
    path:"/become-a-child-director"
  },
  {
    id: "mobMenuTitleLink",
    item: "TitleLink",
    label: "Shop to Support",
    path: "https://www.hope5d.com",
    target: "_blank",
    rel: "noreferrer noopener"
  }
];

export default MobileNavRoutes;