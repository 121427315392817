const DesktopNavRoutes = [
  {
    id: "menuItem01",
    label: "About Us",
    submenu: [
      {
        id: "subMenu01",
        heading: "About Us",
        text: "At HOPE, we believe in the transformative power of compassion and collective action.",
        subheadingOne: "About Us",
        subheadingTwo: "What We Do",
        video: "https://hohopevideoorg.s3.eu-west-2.amazonaws.com/Media+Centre/Slot+4/HOPE+Media+Centre_Rhinos.mov"
      }
    ]
  },
  {
    id: "menuItem02",
    label: "Help Us",
    submenu: [
      {
        id: "subMenu02",
        heading: "Help Us",
        text: "Help us with our ongoing projects, upcoming events, and how you can play a vital role in shaping a brighter, more compassionate world.",
        subheadingOne: "How You Can Help",
        video: "https://hohopevideoorg.s3.eu-west-2.amazonaws.com/Media+Centre/Slot+2/HOPE+Media+Centre+_GK.mov"
      }
    ]
  },
  {
    id: "menuItem03",
    label: "Little Heroes",
    submenu: [
      {
        id: "subMenu03",
        heading: "Little Heroes",
        text: "In a world of trouble and turmoil, where children are pawns in an adult world, children are left helpless and without HOPE. Little Heroes, Big Mission is our TV Show where 12 different children each month, are seta charitable mission.",
        subheadingOne: "You Can Be Part of It",
        video: "https://hohopevideoorg.s3.eu-west-2.amazonaws.com/Little+Heroes/Little+heroes+Promo+RoughCut+07B.mov"
      }
    ]
  },
  {
    id: "menuItem04",
    label: "Shop to Support",
    path:"https://www.hope5d.com",
    target: "_blank",
    rel: "noreferrer noopener"
  }
]

const aboutUsMenu = [
  {
    id: "sub01Menu01-1",
    label: "About HOPE",
    path:"/about-us"
  },
  {
    id: "sub01Menu01-2",
    label: "Ziggy's Story",
    path:"/ziggy's-story"
  }
];

const whatWeDoMenu = [
  {
    id: "sub01Menu02-1",
    label: "Charitable Operations",
    path:"/charitable-operations"
  },
  {
    id: "sub01Menu02-2",
    label: "Disaster Relief",
    path:"/disaster-relief"
  },
  {
    id: "sub01Menu02-3",
    label: "Endangered Animals",
    path:"/endangered-animals"
  },
  {
    id: "sub01Menu02-4",
    label: "Free Education for Kids",
    path:"/page-under-development"
  }
];

const howYouCanHelpMenu = [
  {
    id: "sub02Menu01-1",
    label: "Donate",
    path:"https://www.paypal.com/donate/?hosted_button_id=J7NBS6WZ67U52",
    target: "_blank",
    rel: "noreferrer noopener"
  },
  {
    id: "sub02Menu01-2",
    label: "Shop to Help",
    path:"https://www.hope5d.com",
    target: "_blank",
    rel: "noreferrer noopener"
  }
];

const youCanBeAPartOfItMenu = [
  {
    id: "sub03Menu01-2",
    label: "Little Heroes Big Missions",
    path:"/little-heroes-big-missions"
  },
  {
    id: "sub03Menu01-1",
    label: "Become a Little Hero",
    path:"/become-a-child-director"
  }
];

export { DesktopNavRoutes, aboutUsMenu, whatWeDoMenu, howYouCanHelpMenu, youCanBeAPartOfItMenu }