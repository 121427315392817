const aboutUs = [
  {
    pages: "/images/aboutUs/pages_1.png"
  },
  {
    pages: "/images/aboutUs/pages_2.png"
  },
  {
    pages: "/images/aboutUs/pages_3.png"
  },
  {
    pages: "/images/aboutUs/pages_4.png"
  },
  {
    pages: "/images/aboutUs/pages_5.png"
  },
  {
    pages: "/images/aboutUs/pages_6.png"
  },
  {
    pages: "/images/aboutUs/pages_7.png"
  },
  {
    pages: "/images/aboutUs/pages_8.png"
  },
  {
    pages: "/images/aboutUs/pages_9.png"
  },
  {
    pages: "/images/aboutUs/pages_10.png"
  }
];

export default aboutUs;