import { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ScrollToTop } from '../components';
import '../styles/GlobalStyles.css';

const Index = lazy(() => import('../pages/Index'));
const EndangeredAnimals = lazy(() => import('../pages/EndangeredAnimals'));
const SurvivalTips = lazy(() => import('../pages/SurvivalTips'));
const LittleHeroes = lazy(() => import('../pages/LittleHeroes'));
const ChildDirector = lazy(() => import('../pages/ChildDirector'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const ContactForm = lazy(() => import('../pages/ContactForm'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const AllAboutKids = lazy(() => import('../pages/AllAboutKids'));
const MediaCentre = lazy(() => import('../pages/MediaCentre'))
const ZiggyStory = lazy (() => import('../pages/ZiggyStory'))
const DisasterRelief = lazy(() => import('../pages/DisasterRelief'));
const CharitableOperations = lazy(() => import('../pages/CharitableOperations'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const EnvironmentalPolicy = lazy(() => import('../pages/EnvironmentalPolicy'));
const DonationsPolicy = lazy(() => import('../pages/DonationsPolicy'));
const FundraisingPolicy = lazy(() => import('../pages/FundraisingPolicy'));
const PageUnderDev = lazy(() => import('../pages/PageUnderDev'));
const AppUnderDev = lazy(() => import('../pages/AppUnderDev'));
const NotFound = lazy(() => import('../pages/NotFound'));

function Routes() {
  // Detect browser window size
  const [windowDimension, detectW] = useState({
    winWidth: window.innerWidth
  });

  const detectSize = () => {
    detectW({
      winWidth: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension.winWidth]);

  // Font scaling for accessibility
  
  // State to manage the font scale
  const [fontScale, setFontScale] = useState(1.0);

  // Load font scale from local storage on component mount
  useEffect(() => {
    const storedFontScale = localStorage.getItem('fontScale');

    if (storedFontScale) {
      setFontScale(parseFloat(storedFontScale));
    }
  }, []);

  // Update local storage when font scale changes
  useEffect(() => {
    localStorage.setItem('fontScale', fontScale);
  }, [fontScale]);

  // Minimum and maximum font scales
  const minFontScale = 1.0;
  const maxFontScale = 1.5;

  // Functions to decrease and increase font scale
  const decreaseFontScale = () => {
    setFontScale(prevScale => Math.max(prevScale - 0.1, minFontScale));
  };

  const increaseFontScale = () => {
    setFontScale(prevScale => Math.min(prevScale + 0.1, maxFontScale));
  };

  // Function to reset font scale
  const resetFontScale = () => {
    setFontScale(1.0);
  };

	return (
    <ScrollToTop>
      <Suspense fallback={<div><h1>Loading...</h1></div>}>
        <Switch>
          <Route exact path="/">
            <Index 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/endangered-animals">
            <EndangeredAnimals 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/survival-tips">
            <SurvivalTips />
          </Route>
          <Route path="/little-heroes-big-missions">
            <LittleHeroes 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/become-a-child-director">
            <ChildDirector windowDimension={windowDimension} />
          </Route>
          <Route path="/contact-us">
            <ContactUs 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/contact-form">
            <ContactForm />
          </Route>
          <Route path="/about-us">
            <AboutUs windowDimension={windowDimension} />
          </Route>
          <Route path="/it's-all-about-kids">
            <AllAboutKids 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/media-centre">
            <MediaCentre />
          </Route>
          <Route path="/ziggy's-story">
            <ZiggyStory />
          </Route>
          <Route path="/disaster-relief">
            <DisasterRelief
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/charitable-operations">
            <CharitableOperations />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/environmental-policy">
            <EnvironmentalPolicy 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/donations-policy">
            <DonationsPolicy 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/fundraising-policy">
            <FundraisingPolicy 
              decreaseFontScale={decreaseFontScale}
              increaseFontScale={increaseFontScale}
              resetFontScale={resetFontScale}
              windowDimension={windowDimension}
              fontScale={fontScale}
            />
          </Route>
          <Route path="/page-under-development">
            <PageUnderDev />
          </Route>
          <Route path="/app-under-development">
            <AppUnderDev />
          </Route>
          <Route status={404}>
            <NotFound />
          </Route>
        </Switch>
      </Suspense> 
    </ScrollToTop>
  );
}

export default Routes;