const DesktopFooterRoutes = [
  {
    "LEARN" : [
      {
        class: "learn",
        label: "Get HOPE ART",
        path: "http://hope3g.com/buy-now",
        target: "_blank",
        rel: "noreferrer noopener",
        type: "external"
      },
      {
        class: "learn",
        label: "Endangered Animals",
        path: "/endangered-animals",
        type: "internal"
      },
      {
        class: "learn",
        label: "Survival Tips",
        path: "/survival-tips",
        type: "internal"
      }
    ],
    "SUPPORT": [
      {
        class: "support",
        label: "Become A Child Director",
        path: "/become-a-child-director",
        type: "internal"
      },
      {
        class: "support",
        label: "Shop With HOPE",
        path: "https://www.hope5d.com",
        target: "_blank",
        rel: "noreferrer noopener",
        type: "external"
      },
      {
        class: "support",
        label: "Donate",
        path: "https://www.paypal.com/donate/?hosted_button_id=J7NBS6WZ67U52",
        target: "_blank",
        rel: "noreferrer noopener",
        type: "external"
      },
      {
        class: "support",
        label: "Contact Us",
        path: "/contact-us",
        type: "internal"
      }
    ]
  },
  {
    "ABOUT": [
      {
        class: "about",
        label: "About Us",
        path: "/about-us",
        type: "internal"
      },
      {
        class: "about",
        label: "It's All About Kids",
        path: "/it's-all-about-kids",
        type: "internal"
      },
      {
        class: "about",
        label: "Media Centre",
        path: "/media-centre",
        type: "internal"
      },
      {
        class: "about",
        label: "Ziggy's Story",
        path: "/ziggy's-story",
        type: "internal"
      },
      {
        class: "about",
        label: "Disaster Relief",
        path: "/disaster-relief",
        type: "internal"
      },
      {
        class: "about",
        label: "Charitable Operations",
        path: "/charitable-operations",
        type: "internal"
      }
    ],
    "SOCIAL LINKS": [
      {
        icon: "/images/footerImages/instagram.svg",
        path: "https://www.instagram.com/humanitarianoperations/?hl=en",
        target: "_blank",
        rel: "noreferrer noopener",
        alt: "Instagram",
        type: 'image'
      },
      {
        icon: "/images/footerImages/linkedin.svg",
        path: "https://www.linkedin.com/company/humanitarianoperations",
        target: "_blank",
        rel: "noreferrer noopener",
        alt: "LinkedIn",
        type: 'image'
      },
      {
        icon: "/images/footerImages/facebook.svg",
        path: "https://www.facebook.com/humanitarianoperations",
        target: "_blank",
        rel: "noreferrer noopener",
        alt: "Facebook",
        type: 'image'
      }
    ]
  },
  {
    "LEGAL": [
      {
        class: "legal",
        label: "Terms and Conditions",
        path: "/terms-and-conditions",
        type: "internal"
      },
      {
        class: "legal",
        label: "Privacy Policy",
        path: "/privacy-policy",
        type: "internal"
      },
      {
        class: "legal",
        label: "Environmental Policy",
        path: "/environmental-policy",
        type: "internal"
      },
      {
        class: "legal",
        label: "Donations Policy",
        path: "/donations-policy",
        type: "internal"
      },
      {
        class: "legal",
        label: "Fundraising Policy",
        path: "/fundraising-policy",
        type: "internal"
      }
    ]
  }
];

export default DesktopFooterRoutes;