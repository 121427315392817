import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { aboutUsMenu, whatWeDoMenu, howYouCanHelpMenu, youCanBeAPartOfItMenu } from '../../routes/DesktopNavRoutes';

const MenuDropdowns = ({ submenus, dropdowns }) => {
  return (
    <div className={`drop-menu ${dropdowns ? "show" : ""}`}>
      {submenus.map((submenu) => (
      <div key={submenu.id} id={submenu.id} className="dropdown-content">
        <div className="menu-intro">
          <h1>{submenu.heading}</h1>
          <p>{submenu.text}</p>
        </div>
        <div>
          <h2>{submenu.subheadingOne}</h2>
          {submenu.subheadingOne === "About Us" ? (
            <ul className="desktop-menu-links">
              {aboutUsMenu.map((route) => (
                <li key={route.id} id={route.id}>
                  <Link className="dropdown-menu-link" to={route.path}>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          ) : submenu.subheadingOne === "How You Can Help" ? (
            <ul className="desktop-menu-links">
              {howYouCanHelpMenu.map((route) => (
                <li key={route.id} id={route.id}>
                  <a 
                    className="dropdown-menu-link"
                    href={route.path}
                    target={route.target}
                    rel={route.rel}
                  >
                    {route.label}
                  </a>
              </li>
              ))}
            </ul>
          ) : (
            <ul className="desktop-menu-links">
              {youCanBeAPartOfItMenu.map((route) => (
                <li key={route.id} id={route.id}>
                  <Link className="dropdown-menu-link" to={route.path}>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <h2>{submenu.subheadingTwo}</h2>
          {submenu.subheadingTwo === "What We Do" ? (
            <ul className="desktop-menu-links">
              {whatWeDoMenu.map((route) => (
              <li key={route.id} id={route.id}>
                <Link className="dropdown-menu-link" to={route.path}>
                  {route.label}
                </Link>
              </li>
            ))}
            </ul>
          ) : ""}
        </div>
        <div className="menu-video">
          <ReactPlayer 
            className="menu-react-player"
            controls={true}
            playing={dropdowns ? true : false}
            volume={null}
            muted={true}
            loop={true}
            url={submenu.video}  
            width="100%"
            height="100%"  
          />    
        </div>
      </div>
      ))}
    </div>
  );
};

export default MenuDropdowns;